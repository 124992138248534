import * as React from 'react';
import {HeadFC, Link} from 'gatsby';
import {Container, Grid, Typography} from '@mui/material';
import SkewedPanel from '../components/SkewedPanel';


const NotFoundPage = () => {
  return (
    <>
      <SkewedPanel variant={'dark'} noTopMargin={true}>
        <Typography variant={'h1'} align={'center'} color={'white'}>Stran ne obstaja</Typography>
      </SkewedPanel>
      <Container sx={{pt: '50px'}}>
        <Grid alignItems={'center'}>
          <Typography variant={'body1'} align={'center'}>Željena stran ne obstaja.</Typography>
          <Link to='/'><Typography variant={'body1'} align={'center'}>Domov</Typography></Link>
        </Grid>
      </Container>
    </>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Žvan | 404 Not found</title>;
