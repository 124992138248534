import {styled, useTheme} from '@mui/material';
import * as React from 'react';
import {ReactNode} from 'react';

export type SkewedPanelProps = {
  children: ReactNode;
  variant: 'dark' | 'light';
  noTopMargin?: boolean;
  id?: string;
};

export default function SkewedPanel({children, variant, noTopMargin = false, id}: SkewedPanelProps) {
  const theme = useTheme();
  const backgroundColor = variant === 'dark' ? theme.palette.primary.dark : theme.palette.background.paper;
  const separatorHeight = '50px';
  const ContentPanel = styled('div')(({theme}) => ({
    backgroundColor: backgroundColor,
    padding: '40px 0',
    marginTop: `${noTopMargin ? '0' : separatorHeight} 0`,
    marginBottom: separatorHeight,
    position: 'relative',
    boxSizing: 'border-box',
    '&::before': {
      display: 'block',
      height: separatorHeight,
      width: '100%',
      background: `url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="${backgroundColor}" points="0,100 100,0 100,100"/></svg>`,
      )}')`,
      backgroundSize: '100% 100%',
      content: '""',
      position: 'absolute',
      top: `-${separatorHeight}`,
    },
    '&::after': {
      height: separatorHeight,
      width: '100%',
      background: `url('data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="${backgroundColor}" points="0,0 100,0 0,100"/></svg>`,
      )}')`,
      backgroundSize: '100% 100%',
      content: '""',
      position: 'absolute',
      bottom: `-${separatorHeight}`,
    },
  }));
  return <ContentPanel id={id}>{children}</ContentPanel>;
}
